import {
  Button,
  Center, H4,
  HStack,
  Input,
  notif,
  P1,
  P2,
  Section,
  SmallText,
  Spacer,
  Stack,
  SubtleCard,
  useConfig,
  useDetectBrowser,
  VStack
} from "@mailbrew/uikit";
import { goToSignupDetails } from "components/ChooseSignupMethod";
import TitleDivider from "components/TitleDivider";
import api from "dependencies/api";
import { useFormik } from "formik";
import loggedOutPage from "hoc/loggedOutPage";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import notifApiError from "utils/notifApiError";
import * as yup from "yup";
import { MailbrewLogo } from "../components/brand/MailbrewLogo";
import Page from "../components/Page";
import StyledLink from "../components/StyledLink";
import useResetError from "../hooks/useResetError";
import { appErrorSelector, appLoadingSelector } from "../reducers/appReducer";
import { handleLogin, login } from "../reducers/authReducer";
import urls from "../urls";
import { signupWithTwitter } from "../utils/twitterAuth";

const [LOGIN_MODE_PASSWORD, LOGIN_MODE_CODE] = [0, 1];
const [PAGE_ASK_EMAIL, PAGE_USE_LOGIN_CODE] = [0, 1];

const LoginPage = () => {
  const config = useConfig();
  const router = useRouter();
  const [mode, setMode] = useState(LOGIN_MODE_CODE);
  const [codeModePage, setCodeModePage] = useState(PAGE_ASK_EMAIL);

  const { next, email: urlEmail } = router.query;
  const redirectAfterLoginPath = next;

  const handleLoginWithTwitter = () => {
    signupWithTwitter((twitterSignupId) => {
      goToSignupDetails({ twitterSignupId });
    });
  };

  const handleChangeMode = () => {
    if (mode === LOGIN_MODE_CODE) {
      setMode(LOGIN_MODE_PASSWORD);
    } else if (mode === LOGIN_MODE_PASSWORD) {
      setMode(LOGIN_MODE_CODE);
    }
  };

  return (
    <Page title="Login" noLogin noNavigation>
      <Section pt={0} center width="400px">
        {codeModePage !== PAGE_USE_LOGIN_CODE && (
          <Center mb={10}>
            <P1 align="center" color={config.colors.c3} mb={1.6}>
              Continue to
            </P1>
            <MailbrewLogo size="38px" />
          </Center>
        )}
        <Stack align="stretch" vertical gap={0} width="100%">
          {mode === LOGIN_MODE_PASSWORD && (
            <LoginWithEmailAndPassword
              urlEmail={urlEmail}
              redirectAfterLoginPath={redirectAfterLoginPath}
              onChangeMode={handleChangeMode}
            />
          )}
          {mode === LOGIN_MODE_CODE && (
            <LoginWithCode
              urlEmail={urlEmail}
              redirectAfterLoginPath={redirectAfterLoginPath}
              onChangeMode={handleChangeMode}
              page={codeModePage}
              setPage={setCodeModePage}
            />
          )}
        </Stack>
        {codeModePage !== PAGE_USE_LOGIN_CODE && (
          <>
            <Center>
              <TitleDivider title="or" my={8} />
              <Button width="100%" icon="twitter" color="rgba(29,161,242,1.00)" onClick={handleLoginWithTwitter}>
                Login with Twitter
              </Button>
            </Center>
            <Spacer size="m" />
            <Center>
              <P2>
                New user?{" "}
                <StyledLink variant="link" to={urls.signup()}>
                  Sign up here.
                </StyledLink>
              </P2>
            </Center>
          </>
        )}
      </Section>
    </Page>
  );
};

const LoginWithCode = ({ urlEmail, onChangeMode, redirectAfterLoginPath, page, setPage }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");

  useEffect(() => {
    setEmail(urlEmail);
  }, [urlEmail]);

  const handleChangeEmail = (e) => {
    setEmail(e.target.value.trim());
  };

  const handleChangePage = () => {
    if (page === PAGE_ASK_EMAIL) {
      setPage(PAGE_USE_LOGIN_CODE);
    } else if (page === PAGE_USE_LOGIN_CODE) {
      setPage(PAGE_ASK_EMAIL);
    }
  };

  const handleUseLoginCode = async (code) => {
    try {
      const res = await api.post("/verify-login-code/", { email: email, code });
      dispatch(handleLogin(res.data, redirectAfterLoginPath));
    } catch (err) {
      notifApiError(err, "Error using your code");
    }
  };

  return (
    <>
      {page === PAGE_ASK_EMAIL && (
        <LoginWithCodeAskEmail
          showLabel={urlEmail}
          email={email}
          onChangeEmail={handleChangeEmail}
          onChangeMode={onChangeMode}
          onChangePage={handleChangePage}
        />
      )}
      {page === PAGE_USE_LOGIN_CODE && (
        <LoginWithCodeUseCode email={email} onGoBack={handleChangePage} onUseLoginCode={handleUseLoginCode} />
      )}
    </>
  );
};

const LoginWithCodeAskEmail = ({ email, showLabel, onChangeEmail, onChangeMode, onChangePage }) => {
  const config = useConfig();
  const { hasTouch } = useDetectBrowser();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    if (!email || email.length === 0) {
      notif.error("Please insert your username or email");
      return;
    }

    try {
      setLoading(true);
      await api.post("/login-code/", { email });
      onChangePage();
    } catch (err) {
      notifApiError(err, "Error getting login code, please retry");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <VStack align="stretch" mb={2} gap={2}>
        <Input
          name="email"
          label={showLabel && "Email or username"}
          placeholder="Email or username"
          autoFocus={!hasTouch}
          value={email}
          onChange={onChangeEmail}
          onSubmit={handleSubmit}
        />
      </VStack>
      <HStack align="spaced" noWrap gap={4}>
        <Button
          disabled={loading}
          variant="link"
          style={{ fontWeight: "normal" }}
          color={config.colors.c2}
          onClick={onChangeMode}
          fontSize="14px"
        >
          Log in with password
        </Button>
        <Button w="158px" loading={loading} onClick={handleSubmit}>
          Get login code
        </Button>
      </HStack>
    </>
  );
};

const LoginWithCodeUseCode = ({ email, onGoBack, onUseLoginCode }) => {
  const config = useConfig();
  const [loginCode, setLoginCode] = useState("");

  const handleUpdateCode = (e) => {
    setLoginCode(e.target.value);
  };

  const handleSubmit = () => {
    if (!loginCode || loginCode.length === 0) notif.error("Please insert the login code");
    onUseLoginCode(loginCode);
  };

  return (
    <>
      <VStack align="stretch" mb={3} gap={2}>
        <H4 align="center">Code sent to your inbox</H4>
        {email && <P1 mb={4} align="center">
          If <strong>{email}</strong> is a valid Mailbrew {isEmail(email) ? "email" : "username"}, you'll receive a login code shortly.
        </P1>}
        <Input
          key="code-field"
          placeholder="Insert code here"
          value={loginCode}
          onChange={handleUpdateCode}
          autoFocus
          onSubmit={handleSubmit}
        />
      </VStack>
      <HStack align="spaced" noWrap gap={2}>
        <Button variant="link" icon="chevronLeft" color={config.colors.c3} onClick={onGoBack}>
          Back
        </Button>
        <Button w="158px" onClick={handleSubmit}>
          Login
        </Button>
      </HStack>
      <Center mt={5}>
        <SubtleCard>
          <Stack w="100%" align="center">
            <P2 color={config.colors.c2} align="center">
              <strong>Still no code?</strong> Check your spam folder too.
            </P2>
            <P2 color={config.colors.c2} align="center">
              <a href="mailto:support@mailbrew.com?subject=Can't%20login%20to%20Mailbrew">Click here</a> to contact us.
            </P2>
          </Stack>
        </SubtleCard>
      </Center>
    </>
  );
};

function isEmail(str) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(str);
}

const LoginWithEmailAndPassword = ({ urlEmail, redirectAfterLoginPath, onChangeMode }) => {
  const config = useConfig();
  const { hasTouch } = useDetectBrowser();
  const loading = useSelector(appLoadingSelector);
  const loginError = useSelector(appErrorSelector("login"));
  const dispatch = useDispatch();

  useResetError("login");

  // set email from url
  useEffect(() => {
    if (urlEmail) {
      formik.setFieldValue("email", urlEmail);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlEmail]);

  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema: yup.object().shape({
      email: yup.string(),
      password: yup.string().required("Please type your password"),
    }),
    onSubmit: (values) => {
      dispatch(login(values.email, values.password, redirectAfterLoginPath));
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <VStack align="stretch" mb={2} gap={2}>
        <Input
          label={urlEmail && "Email or username"}
          name="email"
          placeholder="Email or username"
          autoFocus={!hasTouch}
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && formik.errors.email}
        />
        <Input
          type="password"
          name="password"
          placeholder="Password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.password && formik.errors.password}
        />
        {loginError && <SmallText color={config.Input.colorError}>{loginError}</SmallText>}
      </VStack>
      <HStack align="spaced" noWrap>
        <Button
          disabled={loading}
          variant="link"
          style={{ fontWeight: "normal" }}
          color={config.colors.c2}
          onClick={onChangeMode}
          fontSize="14px"
        >
          Log in with code
        </Button>
        <Button w="158px" disabled={loading} loading={loading} submit>
          Login
        </Button>
      </HStack>
    </form>
  );
};

export default loggedOutPage(LoginPage);
